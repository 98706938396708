import { useContext } from "react";
import classes from "../dashboard/dashboard.module.css";
import QrReader from "modern-react-qr-reader";
import GeneralContext from "../../context/generalContext";
import { CircularProgress } from "@chakra-ui/react";

function ScanTicket() {
  const { handleScan, handleError, scanning } = useContext(GeneralContext);

  return (
    <div className={classes.generate_ticket_container}>
      <h3 className={classes.page_heading}>Scan ticket</h3>
      <p className={classes.heading_description}>
        Kindly place your guest's ticket in-front of the camera to scan
      </p>
      {scanning && (
        <>
          <div className={classes.modal_background}></div>
          <CircularProgress
            className={classes.scanning_icon}
            isIndeterminate
            color="purple.300"
          />
        </>
      )}

      <QrReader
        delay={1500}
        constraints={{
          facingMode: "environment",
        }}
        onError={handleError}
        onScan={handleScan}
        style={{ width: "100%" }}
      />
      {/* <p>{ticketCode}</p> */}
    </div>
  );
}
export default ScanTicket;
