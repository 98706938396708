/* eslint-disable react-hooks/exhaustive-deps */
import classes from "./register.module.css";
import { BsPersonCheck } from "react-icons/bs";
import { CgPassword } from "react-icons/cg";
import { AiOutlineCheckCircle, AiFillEye } from "react-icons/ai";
import { FaEyeSlash } from "react-icons/fa";
import { FcCancel } from "react-icons/fc";
import { LuMailQuestion } from "react-icons/lu";
import { useContext, useEffect, useState } from "react";
import GeneralContext from "../../context/generalContext";
import { CircularProgress } from "@chakra-ui/react";

function Register() {
  const {
    toggleRegisterModal,
    registerCredentials,
    updateRegisterCredentialState,
    onRegisterButtonClick,
    passwordGuide,
    setPasswordGuide,
    loading,
  } = useContext(GeneralContext);

  const [registerEyeIcon, setRegisterIcon] = useState({
    password: false,
    reEnterPassword: false,
  });

  // toggle the eye for password
  const togglePasswordEye = () => {
    setRegisterIcon((prev) => ({
      ...prev,
      password: !registerEyeIcon.password,
    }));
  };

  // toggle the eye for re-enter password
  const toggleReEnterPasswordEye = () => {
    setRegisterIcon((prev) => ({
      ...prev,
      reEnterPassword: !registerEyeIcon.reEnterPassword,
    }));
  };

  // update password guide
  const onUpdatePasswordGuide = () => {
    // if the length of the password isn't more than 6 characters, update the minimum_of_6_characters state to true
    if (registerCredentials.password.length > 6) {
      setPasswordGuide((prev) => ({
        ...prev,
        minimum_of_6_characters: true,
      }));
    }

    // if the length of the password isn't more than 6 characters, revert the minimum_of_6_characters state back to false
    if (registerCredentials.password.length <= 6) {
      setPasswordGuide((prev) => ({
        ...prev,
        minimum_of_6_characters: false,
      }));
    }

    // if the password contains a digit, update the contains_a_digit state to true
    if (/\d/.test(registerCredentials.password)) {
      setPasswordGuide((prev) => ({
        ...prev,
        contains_a_digit: true,
      }));
    }

    // if the password doesn't contain a digit, revert the contains_a_digit state back to false
    if (/\d/.test(registerCredentials.password) !== true) {
      setPasswordGuide((prev) => ({
        ...prev,
        contains_a_digit: false,
      }));
    }

    // if the password contains a symbol, update the contains_a_symbol state to true
    const symbolRegex = /[!@#$%^&*()_+{}[\]:;<>,.?~\\|-]/;
    if (symbolRegex.test(registerCredentials.password)) {
      setPasswordGuide((prev) => ({
        ...prev,
        contains_a_symbol: true,
      }));
    }

    // if the password doesn't contain a symbol, revert the contains_a_symbol state back to false
    if (symbolRegex.test(registerCredentials.password) !== true) {
      setPasswordGuide((prev) => ({
        ...prev,
        contains_a_symbol: false,
      }));
    }
  };

  useEffect(() => {
    onUpdatePasswordGuide();
  }, [registerCredentials.password]);

  return (
    <div className={classes.container}>
      <div className={classes.modal_background}></div>

      <div className={classes.white_card}>
        <h4 className={classes.heading}>Register</h4>

        {/* email */}
        <div className={classes.input_container}>
          <LuMailQuestion className={classes.placeholder_icon} />
          <input
            type="email"
            name="email"
            value={registerCredentials.email}
            onChange={updateRegisterCredentialState}
            className={classes.input}
            placeholder="EMAIL ADDRESS"
          />
        </div>

        {/* username */}
        <div className={classes.input_container}>
          <BsPersonCheck className={classes.placeholder_icon} />
          <input
            type="text"
            name="username"
            value={registerCredentials.username}
            onChange={updateRegisterCredentialState}
            className={classes.input}
            placeholder="USERNAME"
          />
        </div>

        {/* password */}
        <div className={classes.input_container}>
          <CgPassword className={classes.placeholder_icon} />
          <input
            type={registerEyeIcon.password === true ? "text" : "password"}
            name="password"
            value={registerCredentials.password}
            onChange={updateRegisterCredentialState}
            className={classes.input}
            placeholder="PASSWORD"
          />
          {registerEyeIcon.password === true ? (
            <FaEyeSlash
              className={classes.placeholder_icon}
              onClick={togglePasswordEye}
            />
          ) : (
            <AiFillEye
              className={classes.placeholder_icon}
              onClick={togglePasswordEye}
            />
          )}
        </div>

        {/* password checks  */}
        <div className={classes.all_password_check_container}>
          {/* Minimum of 6 char. */}
          <div
            className={
              passwordGuide.minimum_of_6_characters
                ? classes.password_check_individual_container_passed
                : classes.password_check_individual_container
            }
          >
            {passwordGuide.minimum_of_6_characters ? (
              <AiOutlineCheckCircle className={classes.check_icon} />
            ) : (
              <FcCancel className={classes.check_icon} />
            )}

            <p>Minimum of 6 characters </p>
          </div>

          {/* Contains a number */}
          <div
            className={
              passwordGuide.contains_a_digit
                ? classes.password_check_individual_container_passed
                : classes.password_check_individual_container
            }
          >
            {passwordGuide.contains_a_digit ? (
              <AiOutlineCheckCircle className={classes.check_icon} />
            ) : (
              <FcCancel className={classes.check_icon} />
            )}
            <p>Contains a number </p>
          </div>

          {/* contains a symbol */}
          <div
            className={
              passwordGuide.contains_a_symbol
                ? classes.password_check_individual_container_passed
                : classes.password_check_individual_container
            }
          >
            {passwordGuide.contains_a_symbol ? (
              <AiOutlineCheckCircle className={classes.check_icon} />
            ) : (
              <FcCancel className={classes.check_icon} />
            )}
            <p>Contains a symbol(e.g @#$%^&*) </p>
          </div>
        </div>

        {/* re-enter password */}
        <div className={classes.input_container}>
          <CgPassword className={classes.placeholder_icon} />
          <input
            type={
              registerEyeIcon.reEnterPassword === true ? "text" : "password"
            }
            name="reEnteredPassword"
            value={registerCredentials.reEnteredPassword}
            onChange={updateRegisterCredentialState}
            className={classes.input}
            placeholder="RE-ENTER PASSWORD"
          />
          {registerEyeIcon.reEnterPassword === true ? (
            <FaEyeSlash
              className={classes.placeholder_icon}
              onClick={toggleReEnterPasswordEye}
            />
          ) : (
            <AiFillEye
              className={classes.placeholder_icon}
              onClick={toggleReEnterPasswordEye}
            />
          )}
        </div>

        <div className={classes.cta_container}>
          {loading ? (
            <CircularProgress
              className={classes.loading_icon}
              isIndeterminate
              color="purple.300"
            />
          ) : (
            <>
              <p className={classes.cancel_btn} onClick={toggleRegisterModal}>
                Cancel
              </p>
              <p
                className={
                  registerCredentials.email === "" ||
                  registerCredentials.username === "" ||
                  registerCredentials.password === "" ||
                  registerCredentials.reEnteredPassword === ""
                    ? classes.register_btn
                    : classes.register_btn_active
                }
                onClick={onRegisterButtonClick}
              >
                Register{" "}
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Register;
