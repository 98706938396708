export const currencyArray = [
  "NGN",
  "USD",
  "EUR",
  "GBP",
  "JPY",
  "CAD",
  "AUD",
  "INR",
];

currencyArray.sort();
