/* eslint-disable react-hooks/exhaustive-deps */
import classes from "../Register/register.module.css";
import { CgPassword } from "react-icons/cg";
import { AiFillEye } from "react-icons/ai";
import { FaEyeSlash } from "react-icons/fa";
import { LuMailQuestion } from "react-icons/lu";
import { useContext, useState } from "react";
import GeneralContext from "../../context/generalContext";
import { CircularProgress } from "@chakra-ui/react";

function LogIn() {
  const {
    toggleLogInModal,
    logInCredentials,
    updateLogInCredentialState,
    onLoginButtonClick,
    loading,
  } = useContext(GeneralContext);

  const [registerEyeIcon, setRegisterIcon] = useState({
    password: false,
    reEnterPassword: false,
  });

  // toggle the eye for password
  const togglePasswordEye = () => {
    setRegisterIcon((prev) => ({
      ...prev,
      password: !registerEyeIcon.password,
    }));
  };

  return (
    <div className={classes.container}>
      <div className={classes.modal_background}></div>

      <div className={classes.white_card}>
        <h4 className={classes.heading}>LogIn</h4>

        {/* email */}
        <div className={classes.input_container}>
          <LuMailQuestion className={classes.placeholder_icon} />
          <input
            type="email"
            name="email"
            value={logInCredentials.email}
            onChange={updateLogInCredentialState}
            className={classes.input}
            placeholder="EMAIL ADDRESS"
          />
        </div>

        {/* password */}
        <div className={classes.input_container}>
          <CgPassword className={classes.placeholder_icon} />
          <input
            type={registerEyeIcon.password === true ? "text" : "password"}
            name="password"
            value={logInCredentials.password}
            onChange={updateLogInCredentialState}
            className={classes.input}
            placeholder="PASSWORD"
          />
          {registerEyeIcon.password === true ? (
            <FaEyeSlash
              className={classes.placeholder_icon}
              onClick={togglePasswordEye}
            />
          ) : (
            <AiFillEye
              className={classes.placeholder_icon}
              onClick={togglePasswordEye}
            />
          )}
        </div>

        <div className={classes.cta_container}>
          {loading ? (
            <CircularProgress
              className={classes.loading_icon}
              isIndeterminate
              color="purple.300"
            />
          ) : (
            <>
              <p className={classes.cancel_btn} onClick={toggleLogInModal}>
                Cancel
              </p>
              <p
                className={
                  logInCredentials.email === "" ||
                  logInCredentials.password === ""
                    ? classes.register_btn
                    : classes.register_btn_active
                }
                onClick={onLoginButtonClick}
              >
                Log In{" "}
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default LogIn;
