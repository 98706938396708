import { useContext, useEffect } from "react";
import classes from "../dashboard/dashboard.module.css";
import GeneralContext from "../../context/generalContext";
import { ticketCountArray } from "../../utils/ticketCountArray";
import { CircularProgress } from "@chakra-ui/react";
import ThemesModal from "../../utils/Themes/themesModal";

function GenerateTicket() {
  const {
    loading,
    currentUserDetails,
    ticketCredentials,
    setTicketCredentials,
    updateGenerateTicketCredentialState,
    onPreviewButtonClick,
    themesModalVisibility,
  } = useContext(GeneralContext);

  useEffect(() => {
    // Find the event object with the selected eventName from the dropdown on user screen
    const eventsDetails =
      currentUserDetails !== "" &&
      currentUserDetails.user.event.length > 0 &&
      currentUserDetails.user.event.find(
        (event) => event.eventName === ticketCredentials.event
      );

    console.log(eventsDetails);
    // if the event carries details then
    eventsDetails
      ? setTicketCredentials((prev) => ({
          ...prev,

          ticketPrice: eventsDetails.ticketPrice,
          ticketType: eventsDetails.ticketType,
          currency: eventsDetails.currency,
          eventDay: eventsDetails.eventDay,
          eventMonth: eventsDetails.eventMonth,
          eventYear: eventsDetails.eventYear,
          eventTime: eventsDetails.eventTime,
          scanStarts: eventsDetails.scanStarts,
        }))
      : setTicketCredentials((prev) => ({
          ...prev,
          ticketPrice: "TICKET PRICE",
          ticketType: "TICKET TYPE",
          currency: "",
          eventDay: "",
          eventMonth: "",
          eventYear: "",
          eventTime: "",
          scanStarts: "",
        }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketCredentials.event]);

  return (
    <div className={classes.generate_ticket_container}>
      <h3 className={classes.page_heading}>Generate ticket</h3>
      <p className={classes.heading_description}>
        Kindly fill in these details to generate ticket for your guests
      </p>

      <div className={classes.all_inputs_container}>
        {/* name */}
        <div className={classes.input_container}>
          <p className={classes.label}>Name</p>
          <input
            onChange={updateGenerateTicketCredentialState}
            name="name"
            value={ticketCredentials.name}
            className={classes.input}
            placeholder="ENTER NAME"
            type="text"
          />
        </div>

        {/* email */}
        <div className={classes.input_container}>
          <p className={classes.label}>Email</p>
          <input
            onChange={updateGenerateTicketCredentialState}
            name="email"
            value={ticketCredentials.email}
            className={classes.input}
            placeholder="ENTER EMAIL ADDRESS"
            type="email"
          />
        </div>

        {/* Select event */}
        <div className={classes.input_container}>
          <p className={classes.label}>Event name</p>
          <select
            onChange={updateGenerateTicketCredentialState}
            name="event"
            // value={ticketCredentials.event}
            className={classes.input}
          >
            <option>none</option>
            {currentUserDetails &&
              currentUserDetails !== "" &&
              currentUserDetails.user.event.length > 0 &&
              currentUserDetails.user.event.map((item, index) => (
                <option value={item.eventName} key={index}>
                  {item.eventName}-{item.ticketType}
                </option>
              ))}
          </select>
        </div>

        {/* ticket type is gotten from the user api */}
        <div className={classes.input_container}>
          <p className={classes.label}>Ticket type</p>
          <input
            className={classes.input}
            value={ticketCredentials.ticketType}
            disabled
          />
        </div>

        {/* Select ticket count */}
        <div className={classes.input_container}>
          <p className={classes.label}>Ticket count</p>
          <select
            onChange={updateGenerateTicketCredentialState}
            name="ticketCount"
            value={ticketCredentials.ticketCount}
            className={classes.input}
          >
            <option>0</option>
            {ticketCountArray.map((item, index) => (
              <option key={index}>{item}</option>
            ))}
          </select>
        </div>

        {/* ticket price is gotten from the user api */}
        <div className={classes.input_container}>
          <p className={classes.label}>Ticket price</p>
          <input
            disabled
            className={classes.input}
            value={`${ticketCredentials.currency} ${ticketCredentials.ticketPrice}`}
          />
        </div>

        {/* event date is gotten from the user api, then we join the event date, event month & year separating them with a slash*/}
        <div className={classes.input_container}>
          <p className={classes.label}>Event date (DD/MM/YY)</p>
          <input
            disabled
            className={classes.input}
            value={`${ticketCredentials.eventDay}/${ticketCredentials.eventMonth}/${ticketCredentials.eventYear}`}
          />
        </div>

        {/* event time is gotten from the user api */}
        <div className={classes.smaller_input_container}>
          <p className={classes.label}>Show starts</p>
          <input
            disabled
            className={classes.input}
            value={ticketCredentials.eventTime}
          />
        </div>

        {/* scanStarts is gotten from the user api */}
        <div className={classes.smaller_input_container}>
          <p className={classes.label}>Scan starts</p>
          <input
            disabled
            className={classes.input}
            value={ticketCredentials.scanStarts}
          />
        </div>
      </div>

      {/* button */}
      <div className={classes.generate_button_container}>
        {loading ? (
          <CircularProgress
            className={classes.loading_icon}
            isIndeterminate
            color="purple.300"
          />
        ) : (
          <p
            className={
              ticketCredentials.name === "" ||
              ticketCredentials.event === "none" ||
              ticketCredentials.ticketCount === "0" ||
              ticketCredentials.ticketPrice === "TICKET PRICE" ||
              ticketCredentials.ticketType === "TICKET TYPE" ||
              ticketCredentials.email === "" ||
              ticketCredentials.currency === "" ||
              ticketCredentials.eventDay === "" ||
              ticketCredentials.eventMonth === "" ||
              ticketCredentials.eventYear === "" ||
              ticketCredentials.eventTime === "" ||
              ticketCredentials.scanStarts === ""
                ? classes.generate_button_inactive
                : classes.generate_button
            }
            onClick={onPreviewButtonClick}
          >
            Preview
          </p>
        )}
      </div>

      {themesModalVisibility && <ThemesModal />}
    </div>
  );
}
export default GenerateTicket;
