import { FlutterWaveButton, closePaymentModal } from "flutterwave-react-v3";
import classes from "../addEventsPreviewModal/addEventsPreviewModal.module.css";
import { useContext } from "react";
import GeneralContext from "../../context/generalContext";

export default function FLWPayButton(props) {
  const { addEvent } = useContext(GeneralContext);

  const config = {
    public_key: process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY,
    tx_ref: Date.now(),
    amount: process.env.REACT_APP_COST_OF_ADDING_EVENT,
    currency: process.env.REACT_APP_CURRENCY_OF_ADDING_EVENTS,
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: props.email,
      phone_number: props.phone_number,
      name: props.name,
    },
    customizations: {
      title: "Ticket-Masta",
      description: "Payment for Adding Event",
      logo:
        "https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg",
    },
  };

  const fwConfig = {
    ...config,
    text: "Pay with Flutterwave!",
    callback: (response) => {
      if (response.status === "successful") {
        addEvent();
      }
      closePaymentModal(); // this will close the modal programmatically
    },
    onClose: () => {},
  };

  return (
    <div className={classes.proceed_to_payment}>
      <FlutterWaveButton {...fwConfig} />
    </div>
  );
}
