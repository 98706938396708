import { useNavigate } from "react-router-dom";
import classes from "./header.module.css";
import { useContext, useEffect, useState } from "react";
import GeneralContext from "../../context/generalContext";
import { RxHamburgerMenu } from "react-icons/rx";
import { GrClose } from "react-icons/gr";

function Header() {
  const {
    toggleRegisterModal,
    toggleLogInModal,
    isTabletOrMobile,
  } = useContext(GeneralContext);

  const navigate = useNavigate();

  const [isHamburgerMenuActive, setIsHamburgerMenuActive] = useState(true); // this state tells if the hamburger menu is active or not it helps us display nav modals for tablets and mobile

  // toggle nav modal for mobile and tablet
  const toggleNavModal = () => {
    setIsHamburgerMenuActive(!isHamburgerMenuActive);
  };

  //   read windows path
  const pathName = window.location.pathname;

  useEffect(() => {
    isTabletOrMobile && setIsHamburgerMenuActive(false); // here we are saying that when a user visits this site and this user is a tablet or mobile make the hamburger menu inactive thereby not displaying the nav modal on default unlike desktop that displays on default
    !isTabletOrMobile && setIsHamburgerMenuActive(true); // here we are saying the opposite of what we have directly above
  }, [isTabletOrMobile]);

  return (
    <div className={classes.container}>
      <p className={classes.logo} onClick={() => navigate("/")}>
        TICKET MASTA
      </p>
      <div className={classes.hamburger_nav_and_auth_container}>
        {/*  show the cancel button if the viewport is a tablet or mobile & the hamburger menu is active*/}

        {isTabletOrMobile && !isHamburgerMenuActive && (
          <RxHamburgerMenu
            onClick={toggleNavModal}
            className={classes.hamburger_button}
          />
        )}

        {/*  show the cancel button if the viewport is a tablet or mobile & the hamburger menu is active*/}
        {isTabletOrMobile && isHamburgerMenuActive && (
          <GrClose
            onClick={toggleNavModal}
            className={classes.hamburger_button}
          />
        )}

        {isHamburgerMenuActive && (
          <div
            className={classes.nav_and_auth_container}
            onClick={isTabletOrMobile ? toggleNavModal : () => {}}
          >
            <div className={classes.nav_bar_container}>
              <p
                className={
                  pathName === "/"
                    ? classes.nav_items_active
                    : classes.nav_items
                }
                onClick={() => navigate("/")}
              >
                Home
              </p>
              <p
                className={
                  pathName === "/about"
                    ? classes.nav_items_active
                    : classes.nav_items
                }
                onClick={() => navigate("/about")}
              >
                About
              </p>
              <p
                className={
                  pathName === "/contact"
                    ? classes.nav_items_active
                    : classes.nav_items
                }
                onClick={() => navigate("/contact")}
              >
                Contact
              </p>
            </div>

            <div className={classes.auth_nav_bar_container}>
              {localStorage.getItem("tmt") ? (
                <p
                  className={classes.register_button}
                  onClick={() => navigate("/dashboard")}
                >
                  Dashboard
                </p>
              ) : (
                <>
                  <p
                    className={classes.login_button}
                    onClick={toggleLogInModal}
                  >
                    LogIn
                  </p>
                  <p
                    className={classes.register_button}
                    onClick={toggleRegisterModal}
                  >
                    Register
                  </p>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
