import { useState } from "react";
import GeneralContext from "./generalContext";
import { toast } from "react-toastify";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import "../index.css";
import { useMediaQuery } from "react-responsive";

const GeneralState = ({ children }) => {
  const navigate = useNavigate();

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  // instead of typing it out everywhere we decided to declare it, this makes the code easy to read & edit
  const notificationProps = {
    theme: "dark",
    position: "top-center",
  };

  // backend server url
  const url = process.env.REACT_APP_BACKEND_SERVER_URL;

  const [registerModal, setRegisterModal] = useState(false); //register modal visibility state
  const [logInModal, setLogInModal] = useState(false); //login modal visibility state
  const [currentUserDetails, setCurrentUserDetails] = useState(""); //the state that holds all the user details gotten fromm the backend after users have logged in
  const [themesModalVisibility, setThemesModalVisibility] = useState(false); // the state that holds the visibility of the modal that allows users to select theme
  const [
    previewAddEventModalVisibility,
    setPreviewAddEventModalVisibility,
  ] = useState(false); // the state that holds the visibility of the modal that allows users to preview what they have added and cross check before they proceed to payment
  const [allThemes, setAllThemes] = useState([]); // the state that holds all the themes gotten from the server
  const [preview, setPreview] = useState(<div></div>); // the state that holds the preview gotten from the server
  const [scanning, setScanning] = useState(false); // the state that tells if a user is currently scanning a ticket

  // password guide - this is the state helps user to input a strong password
  const [passwordGuide, setPasswordGuide] = useState({
    minimum_of_6_characters: false,
    contains_a_digit: false,
    contains_a_symbol: false,
  });

  // the state that holds all credentials the user needs to register on this platform
  const [registerCredentials, setRegisterCredentials] = useState({
    email: "",
    username: "",
    password: "",
    reEnteredPassword: "",
  });

  // the state that holds all credentials the user needs to add events to his dashboard
  const [addEventCredentials, setAddEventCredentials] = useState({
    eventName: "",
    ticketPrice: "",
    ticketType: "",
    currency: "---",
    eventDay: "DD",
    eventMonth: "MM",
    eventYear: "YY",
    eventTime: "--:--",
    scanStarts: "--:--",
  });

  // the state that holds all credentials the flutterwave needs to confirm payment
  const [flwCredential, setFlwCredential] = useState({
    name: "",
    email: "",
    phoneNumber: "",
  });

  // the state that holds all credentials the user needs to generate ticket on this platform
  const [ticketCredentials, setTicketCredentials] = useState({
    name: "",
    event: "none",
    ticketCount: "0",
    ticketPrice: "TICKET PRICE",
    ticketType: "TICKET TYPE",
    email: "",
    currency: "",
    theme: "",
    eventDay: "",
    eventMonth: "",
    eventYear: "",
    eventTime: "",
    scanStarts: "",
  });

  // the state that holds all credentials the user needs to log in on this platform
  const [logInCredentials, setLogInCredentials] = useState({
    email: "",
    password: "",
  });

  // when the api is connecting with the backend and it's still loading, below is the state to handle it's loading
  const [loading, setLoading] = useState(false);

  //   toggle the register modal visible or not
  const toggleRegisterModal = () => {
    setRegisterModal(!registerModal);
  };

  //   toggle the login modal visible or not
  const toggleLogInModal = () => {
    setLogInModal(!logInModal);
  };

  //   toggle the preview add event modal visible or not
  const togglePreviewAddEventModal = () => {
    setPreviewAddEventModalVisibility(!previewAddEventModalVisibility);
  };

  // update flutterwave credential state
  const updateFLWCredentials = (e) => {
    setFlwCredential((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  // update register credential state
  const updateRegisterCredentialState = (e) => {
    setRegisterCredentials((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  // update login credential state
  const updateLogInCredentialState = (e) => {
    setLogInCredentials((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  // update add events credential state
  const updateAddEventCredentialState = (e) => {
    setAddEventCredentials((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  //   toggle the themes modal visible or not
  const themesModalPopUp = () => {
    setThemesModalVisibility(!themesModalVisibility);
  };

  // update generate ticket credential state without themes
  const updateGenerateTicketCredentialState = (e) => {
    setTicketCredentials((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  //

  // RegEx to check if the email address is valid or not
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; //email reGex

  // when the user clicks the register button
  const onRegisterButtonClick = () => {
    const isEmailValid = emailPattern.test(registerCredentials.email);

    if (registerCredentials.email === "") {
      toast.error("🤦‍♂️ Enter email address!", notificationProps);
    } else if (isEmailValid !== true) {
      toast.error("🤦‍♂️ Your email is invalid!", notificationProps);
    } else if (registerCredentials.username === "") {
      toast.error("🤦‍♂️ Enter username!", notificationProps);
    } else if (registerCredentials.username.length < 3) {
      toast.error(
        "🤦‍♂️ Username should be minimum of 3 letters!",
        notificationProps
      );
    } else if (registerCredentials.username.length > 30) {
      toast.error(
        "🤦‍♂️ Username should be maximum of 30 letters!",
        notificationProps
      );
    } else if (registerCredentials.password === "") {
      toast.error("🤦‍♂️ Enter password!", notificationProps);
    } else if (!passwordGuide.minimum_of_6_characters) {
      toast.error(
        "🤦‍♂️ Password must be a minimum of 6 characters!",
        notificationProps
      );
    } else if (!passwordGuide.contains_a_digit) {
      toast.error("🤦‍♂️ Password must contain a digit!", notificationProps);
    } else if (!passwordGuide.contains_a_symbol) {
      toast.error("🤦‍♂️ Password must contain a symbol!", notificationProps);
    } else if (registerCredentials.password === "") {
      toast.error("🤦‍♂️ Re-enter password!", notificationProps);
    } else if (
      registerCredentials.password !== registerCredentials.reEnteredPassword
    ) {
      toast.error("🤦‍♂️ Passwords don't match!", notificationProps);
    } else {
      setLoading(true);
      registerUserApi(registerCredentials);
    }
  };

  // when the user clicks the preview button to add event
  const onAddEventButtonClick = () => {
    if (addEventCredentials.eventName.length < 3) {
      toast.error(
        "🤦‍♂️ Event name should be minimum of 3 letters!",
        notificationProps
      );
    } else if (addEventCredentials.eventName.length > 30) {
      toast.error(
        "🤦‍♂️ Event name should be maximum of 30 letters!",
        notificationProps
      );
    } else if (addEventCredentials.ticketType === "") {
      toast.error(
        "🤦‍♂️ Enter a ticket type, it can be regular or vip or whatever name you choose to call yours!",
        notificationProps
      );
    } else if (addEventCredentials.currency === "---") {
      toast.error("🤦‍♂️ Select your ticket currency!", notificationProps);
    } else if (addEventCredentials.ticketPrice === "") {
      toast.error("🤦‍♂️ Enter ticket price!", notificationProps);
    } else if (addEventCredentials.eventDay === "DD") {
      toast.error("🤦‍♂️ Select a valid event day!", notificationProps);
    } else if (addEventCredentials.eventMonth === "MM") {
      toast.error("🤦‍♂️ Select a valid event month!", notificationProps);
    } else if (
      (addEventCredentials.eventDay === "30" ||
        addEventCredentials.eventDay === "31") &&
      addEventCredentials.eventMonth === "2"
    ) {
      toast.error(
        "🤦‍♂️ The event month you selected does not have 30 & 31 days!",
        notificationProps
      );
    } else if (
      addEventCredentials.eventDay === "31" &&
      (addEventCredentials.eventMonth === "02" ||
        addEventCredentials.eventMonth === "04" ||
        addEventCredentials.eventMonth === "06" ||
        addEventCredentials.eventMonth === "09" ||
        addEventCredentials.eventMonth === "11")
    ) {
      toast.error(
        "🤦‍♂️ The event month you selected does not have 31 days!",
        notificationProps
      );
    } else if (
      addEventCredentials.eventDay === "29" &&
      addEventCredentials.eventMonth === "2" &&
      parseInt(addEventCredentials.eventYear) % 4 !== 0
    ) {
      toast.error(
        "🤦‍♂️ The event month you selected does not have 29 days!",
        notificationProps
      );
    } else if (addEventCredentials.eventYear === "YY") {
      toast.error("🤦‍♂️ Select a valid event year!", notificationProps);
    } else if (addEventCredentials.eventTime === "--:--") {
      toast.error("🤦‍♂️ Select a valid event time!", notificationProps);
    } else if (addEventCredentials.scanStarts === "--:--") {
      toast.error(
        "🤦‍♂️ Select a valid time to start scanning tickets for guest!",
        notificationProps
      );
    } else {
      togglePreviewAddEventModal();
    }
  };

  // when the user clicks the log in button
  const onLoginButtonClick = () => {
    const isEmailValid = emailPattern.test(logInCredentials.email);

    if (logInCredentials.email === "") {
      toast.error("🤦‍♂️ Enter email address!", notificationProps);
    } else if (isEmailValid !== true) {
      toast.error("🤦‍♂️ Your email is invalid!", notificationProps);
    } else if (logInCredentials.password === "") {
      toast.error("🤦‍♂️ Enter password!", notificationProps);
    } else {
      setLoading(true);
      logInUserApi(logInCredentials);
    }
  };

  // when the user clicks the preview button popup the select theme modal
  const onPreviewButtonClick = () => {
    const isEmailValid = emailPattern.test(ticketCredentials.email);

    if (ticketCredentials.name === "") {
      toast.error("🤦‍♂️ Enter name!", notificationProps);
    } else if (ticketCredentials.name.length < 3) {
      toast.error("🤦‍♂️ Name should be minimum of 3 letters!", notificationProps);
    } else if (ticketCredentials.name.length > 30) {
      toast.error(
        "🤦‍♂️ Username should be maximum of 30 letters!",
        notificationProps
      );
    } else if (ticketCredentials.email === "") {
      toast.error("🤦‍♂️ Enter email address!", notificationProps);
    } else if (isEmailValid !== true) {
      toast.error("🤦‍♂️ Your email is invalid!", notificationProps);
    } else if (ticketCredentials.event === "none") {
      toast.error("🤦‍♂️ Select an event name!", notificationProps);
    } else if (ticketCredentials.ticketCount === "0") {
      toast.error("🤦‍♂️ Select a valid count of ticket!", notificationProps);
    } else {
      themesModalPopUp();
    }
  };

  // when the user clicks the generate button
  const onGenerateButtonClick = () => {
    if (ticketCredentials.theme === "") {
      toast.error("🤦‍♂️ Select a theme!", notificationProps);
    } else {
      setLoading(true);
      generateTicketApi(ticketCredentials);
    }
  };

  const [ticketCode, setTicketCode] = useState("No ticket code");

  // when user tries to scan the qr code
  const handleScan = (data) => {
    if (data && !scanning && !Swal.isVisible()) {
      setTicketCode(data);
      scanTicket({ ticketCode: data });
      setScanning(true);
    }
  };

  // when there is an error in the scan component
  const handleError = (err) => {
    console.error(err);
    setScanning(false);
  };

  //patch api to interact with the backend to scan ticket
  const scanTicket = async (value) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("tmt")}`,
      },
    };

    const body = JSON.stringify(value);
    try {
      const res = await axios.patch(`${url}/ticket/scanTicket`, body, config);
      const data = res.data;

      console.log(data);
      Swal.fire({
        icon: "success",
        title: `😎 ${data.msg}`,
        text: `Kindly give ${data.newTicket.name} ${data.newTicket.ticketCount} ${data.newTicket.ticketType} Tickets`,
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "😣 Heyyyyyyy!",
        text: error.response.data.msg,
      });
    } finally {
      setScanning(false);
    }
  };

  //post api to interact with the backend and register this user
  const registerUserApi = async (credentials) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify(credentials);

    try {
      const res = await axios.post(`${url}/auth/register`, body, config);
      const data = res.data;

      Swal.fire({
        icon: "success",
        title: "😎Thank You!",
        text: `We welcome you, ${data.user.username}! Within 48hours our team would check if you have met satisfactory requirements to be on our platform and notify you as soon as our checks are over.`,
        footer: '<a href="">Why do I have this issue?</a>',
      }).then(() => {
        toggleRegisterModal();
      });
    } catch (error) {
      const errorMessage = error.response.data.msg;
      toast.error(`🤦‍♂️ ${errorMessage}`, notificationProps);
    } finally {
      setLoading(false);
    }
  };

  //

  // add events
  const addEvent = () => {
    addEventApi(addEventCredentials);
  };

  //post api to interact with the backend and log in this user
  const logInUserApi = async (credentials) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify(credentials);

    try {
      const res = await axios.post(`${url}/auth/login`, body, config);
      const data = res.data;
      navigate("/dashboard");
      setLogInModal(!logInModal);
      localStorage.setItem("tmt", data.token);
    } catch (error) {
      const errorMessage = error.response.data.msg;
      toast.error(`🤦‍♂️ ${errorMessage}`, notificationProps);
    } finally {
      setLoading(false);
    }
  };

  //post api to interact with the backend and generate ticket based on the data entered
  const generateTicketApi = async (credentials) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("tmt")}`,
      },
    };

    const body = JSON.stringify(credentials);

    try {
      const res = await axios.post(
        `${url}/ticket/generateTicket`,
        body,
        config
      );
      const data = res.data;
      // navigate("/dashboard");
      console.log(data);
      Swal.fire({
        icon: "success",
        title: "😎Thank You!",
        text: data.msg,
      });
    } catch (error) {
      const errorMessage = error.response.data.msg;
      toast.error(`🤦‍♂️ ${errorMessage}`, notificationProps);
    } finally {
      setLoading(false);
    }
  };

  // add event api
  const addEventApi = async (value) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("tmt")}`,
      },
    };
    const body = JSON.stringify(value);
    try {
      const res = await axios.patch(`${url}/ticket/add_event`, body, config);
      const data = res.data;
      setCurrentUserDetails(data);
      togglePreviewAddEventModal();
      Swal.fire({
        icon: "success",
        title: `😎 Boss!`,
        text: `${addEventCredentials.eventName} - ${addEventCredentials.ticketType} ticket has been added!`,
      });
      console.log(data);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: `😣 Heyyyyyyy!`,
        text: error.response.data.msg,
      });
    }
  };

  // get user
  const getUser = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tmt")}`,
      },
    };
    try {
      const res = await axios.get(`${url}/ticket/getUser`, config);
      const data = res.data;

      console.log(data);
      setCurrentUserDetails(data);
    } catch (error) {
      toast.error(error.response.msg);
    }
  };

  // this function interacts with the servers through axios to get all the themes
  const getAllThemes = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tmt")}`,
      },
    };
    try {
      const res = await axios.get(`${url}/theme`, config);
      const data = res.data;

      console.log(data);
      setAllThemes(data.themes);
    } catch (error) {
      toast.error(error.response.msg);
    }
  };

  // get preview: his api using query parameters interacts with the servers to fetch the preview of ticket based on the the theme selected
  const getPreview = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("tmt")}`,
      },
    };

    try {
      const res = await axios.get(
        `${url}/ticket/?name=${ticketCredentials.name}&currency=${ticketCredentials.currency}&ticketPrice=${ticketCredentials.ticketPrice}&ticketType=${ticketCredentials.ticketType}&ticketCount=${ticketCredentials.ticketCount}&dummyUrl=https://sconchun.sirv.com/Untitled.png&theme=${ticketCredentials.theme}&eventDay=${ticketCredentials.eventDay}&eventMonth=${ticketCredentials.eventMonth}&eventYear=${ticketCredentials.eventYear}&eventTime=${ticketCredentials.eventTime}&scanStarts=${ticketCredentials.scanStarts}`,
        config
      );

      const data = res.data;
      console.log(data);
      setPreview(data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <GeneralContext.Provider
      value={{
        toggleRegisterModal,
        registerModal,
        registerCredentials,
        updateRegisterCredentialState,
        onRegisterButtonClick,
        passwordGuide,
        setPasswordGuide,
        loading,
        logInModal,
        setLogInModal,
        toggleLogInModal,
        logInCredentials,
        updateLogInCredentialState,
        onLoginButtonClick,
        currentUserDetails,
        getUser,
        ticketCredentials,
        setTicketCredentials,
        updateGenerateTicketCredentialState,
        onPreviewButtonClick,
        themesModalPopUp,
        themesModalVisibility,
        getAllThemes,
        allThemes,
        getPreview,
        preview,
        setPreview,
        onGenerateButtonClick,
        handleScan,
        ticketCode,
        scanning,
        handleError,
        addEventCredentials,
        updateAddEventCredentialState,
        onAddEventButtonClick,
        previewAddEventModalVisibility,
        togglePreviewAddEventModal,
        addEvent,
        flwCredential,
        updateFLWCredentials,
        isTabletOrMobile,
      }}
    >
      {children}
    </GeneralContext.Provider>
  );
};
export default GeneralState;
