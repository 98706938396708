import { useContext } from "react";
import classes from "./addEventsPreviewModal.module.css";
import { PiWarningCircleBold } from "react-icons/pi";
import GeneralContext from "../../context/generalContext";
import { CircularProgress } from "@chakra-ui/react";
import FLWPayButton from "../payment/PayButton";

function AddEventsPreviewModal() {
  const {
    addEventCredentials,
    loading,
    togglePreviewAddEventModal,
    flwCredential,
    updateFLWCredentials,
  } = useContext(GeneralContext);
  return (
    <div className={classes.container}>
      <div className={classes.modal_background}></div>

      <div className={classes.preview_events_modal}>
        <PiWarningCircleBold className={classes.warning_icon} />{" "}
        <h3 className={classes.heading}>😎 Take a minute to cross-check</h3>
        <p className={classes.text}>
          You are about to add a{" "}
          <span className={classes.highlighted_word}>
            {addEventCredentials.ticketType}
          </span>{" "}
          ticket for an event called{" "}
          <span className={classes.highlighted_word}>
            {addEventCredentials.event}
          </span>
          . This ticket costs{" "}
          <span className={classes.highlighted_word}>
            {" "}
            {addEventCredentials.currency}
            {addEventCredentials.ticketPrice}
          </span>
          . This event is on{" "}
          <span className={classes.highlighted_word}>
            {addEventCredentials.eventDay}
            {"/"}
            {addEventCredentials.eventMonth}
            {"/"}
            {addEventCredentials.eventYear}{" "}
          </span>
          by{" "}
          <span className={classes.highlighted_word}>
            {addEventCredentials.eventTime}
          </span>
          . Guest can start scanning ticket by{" "}
          <span className={classes.highlighted_word}>
            {addEventCredentials.scanStarts}
          </span>
        </p>
        {/* form section */}
        <p className={classes.Prompt}>
          To proceed, kindly fill the below details{" "}
        </p>
        {/*  FLutter wave credentials*/}
        <input
          type="text"
          name="name"
          value={flwCredential.name}
          onChange={updateFLWCredentials}
          placeholder="ENTER NAME"
          className={classes.flutterwave_credentials_input}
        />
        <input
          name="phoneNumber"
          type="tel"
          onChange={updateFLWCredentials}
          value={flwCredential.phoneNumber}
          placeholder="ENTER PHONE NUMBER"
          className={classes.flutterwave_credentials_input}
        />
        <input
          name="email"
          type="email"
          onChange={updateFLWCredentials}
          value={flwCredential.email}
          placeholder="ENTER EMAIL ADDRESS"
          className={classes.flutterwave_credentials_input}
        />
        {/*  */}
        <div className={classes.cta_buttons_container}>
          {loading ? (
            <CircularProgress
              className={classes.loading_icon}
              isIndeterminate
              color="purple.300"
            />
          ) : (
            <>
              <p className={classes.edit} onClick={togglePreviewAddEventModal}>
                Edit
              </p>
              <>
                <FLWPayButton
                  email={flwCredential.email}
                  name={flwCredential.name}
                  phone_number={flwCredential.phoneNumber}
                />
              </>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
export default AddEventsPreviewModal;
