import { useContext, useEffect } from "react";
import classes from "./themes.module.css";
import GeneralContext from "../../context/generalContext";
import { ticketCountArray } from "../ticketCountArray";
import { CircularProgress } from "@chakra-ui/react";

function ThemesModal() {
  const {
    allThemes,
    themesModalPopUp,
    getAllThemes,
    getPreview,
    ticketCredentials,
    setTicketCredentials,
    preview,
    onGenerateButtonClick,
    loading,
  } = useContext(GeneralContext);

  console.log(preview);
  // when this theme modal comes up fetch all the themes
  useEffect(() => {
    getAllThemes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // when this theme modal comes up fetch all the themes
  useEffect(() => {
    getPreview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketCredentials]);

  return (
    <div className={classes.container}>
      <div className={classes.modal_background}></div>

      {/* There would be two containers here one for selecting the Theme & another for preview */}
      <div className={classes.white_card_and_preview_container}>
        <div className={classes.select_themes_container}>
          <h3 className={classes.page_heading}> Select Theme</h3>

          {/* theme */}
          <div className={classes.all_themes_container}>
            {allThemes.length > 0 &&
              allThemes.map((item, index) => (
                <div
                  onClick={() => {
                    setTicketCredentials((prev) => ({
                      ...prev,
                      theme: item.title,
                    }));
                  }}
                  key={index}
                  className={
                    ticketCredentials.theme === item.title
                      ? classes.theme_container_selected
                      : classes.theme_container
                  }
                >
                  <img
                    className={classes.image}
                    src={item.image}
                    alt={item.description}
                  />
                  <div className={classes.title_and_price_container}>
                    <p className={classes.title}>{item.title}</p>
                    <p className={classes.price}>
                      {" "}
                      {item.currency} {item.price}
                    </p>
                  </div>
                  <p className={classes.description}>{item.description} </p>
                </div>
              ))}
          </div>
          <div className={classes.cta_buttons_container}>
            {loading ? (
              <CircularProgress
                className={classes.loading_icon}
                isIndeterminate
                color="purple.300"
              />
            ) : (
              <>
                <p className={classes.cancel} onClick={themesModalPopUp}>
                  Cancel
                </p>
                <p
                  onClick={onGenerateButtonClick}
                  className={
                    ticketCountArray.theme === ""
                      ? classes.generate_inactive
                      : classes.generate_active
                  }
                >
                  Generate
                </p>
              </>
            )}
          </div>
        </div>

        <div className={classes.preview_container}>
          <h3 className={classes.preview_heading}>preview</h3>
          <div dangerouslySetInnerHTML={{ __html: preview }} />
        </div>
      </div>
    </div>
  );
}
export default ThemesModal;
