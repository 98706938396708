import classes from "../dashboard/dashboard.module.css";
import { BiMessageSquareAdd, BiPrinter } from "react-icons/bi";
import { LuScanLine } from "react-icons/lu";
import {
  MdOutlineEventAvailable,
  MdOutlineSpaceDashboard,
} from "react-icons/md";
import { IoPersonCircleSharp } from "react-icons/io5";
import { GoKebabHorizontal } from "react-icons/go";
import { useContext, useEffect } from "react";
import GeneralContext from "../../context/generalContext";
import { useNavigate } from "react-router-dom";

function SidePane() {
  const navigate = useNavigate("");

  const { currentUserDetails, getUser } = useContext(GeneralContext);

  const pathName = window.location.pathname; // checking for the path name so i can use it to tel the indicator where to be

  // Because the side pane is present in all four (4) dashboard pages/panes, we would say when the side pane page is been opened user details should be gotten from the backend & database
  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.side_pane_container}>
      {/* add event */}
      <div
        className={classes.add_event_container}
        onClick={() => navigate("/dashboard/add_event")}
      >
        <BiMessageSquareAdd className={classes.add_icon} />
        <p className={classes.add_event_text}> Add Event</p>
      </div>

      {/* overview  */}
      <div
        onClick={() => navigate("/dashboard")}
        className={classes.side_pane_nav_item_container}
      >
        <div
          className={
            pathName === "/dashboard"
              ? classes.active_indicator
              : classes.inActiveIndicator
          }
        ></div>
        <MdOutlineSpaceDashboard className={classes.side_pane_icon} />
        <p className={classes.side_pane_text}>Overview</p>
      </div>

      {/* generate ticket */}
      <div
        onClick={() => navigate("/dashboard/generate_ticket")}
        className={classes.side_pane_nav_item_container}
      >
        <div
          className={
            pathName === "/dashboard/generate_ticket"
              ? classes.active_indicator
              : classes.inActiveIndicator
          }
        ></div>
        <BiPrinter className={classes.side_pane_icon} />
        <p className={classes.side_pane_text}>Generate Ticket</p>
      </div>

      {/* scan ticket */}
      <div
        onClick={() => navigate("/dashboard/scan_ticket")}
        className={classes.side_pane_nav_item_container}
      >
        <div
          className={
            pathName === "/dashboard/scan_ticket"
              ? classes.active_indicator
              : classes.inActiveIndicator
          }
        ></div>
        <LuScanLine className={classes.side_pane_icon} />
        <p className={classes.side_pane_text}>Scan Ticket</p>
      </div>

      {/* manage events */}
      <div className={classes.side_pane_nav_item_container}>
        <div
          className={
            pathName === "/dashboard/manage_events"
              ? classes.active_indicator
              : classes.inActiveIndicator
          }
        ></div>
        <MdOutlineEventAvailable className={classes.side_pane_icon} />
        <p className={classes.side_pane_text}>Manage Events</p>
      </div>

      {/* user */}
      <div className={classes.user_container}>
        <IoPersonCircleSharp className={classes.side_pane_icon} />
        <p className={classes.username}>
          {currentUserDetails &&
          currentUserDetails !== "" &&
          currentUserDetails.user
            ? currentUserDetails.user.username
            : "No username"}
        </p>
        <GoKebabHorizontal className={classes.kebab_icon} />
      </div>
    </div>
  );
}
export default SidePane;
