import { CircularProgress } from "@chakra-ui/react";
import { currencyArray } from "../../utils/currencyArray";
import {
  dayArray,
  monthArray,
  timeArray,
  yearArray,
} from "../../utils/dateArrays";
import classes from "../dashboard/dashboard.module.css";
import { useContext } from "react";
import GeneralContext from "../../context/generalContext";
import AddEventsPreviewModal from "../../utils/addEventsPreviewModal/addEventsPreviewModal";

function AddEvent() {
  const costOfAddingEvent = process.env.REACT_APP_COST_OF_ADDING_EVENT;
  const currencyOfAddingEvent = process.env.REACT_APP_CURRENCY_OF_ADDING_EVENTS;

  const {
    loading,
    addEventCredentials,
    updateAddEventCredentialState,
    onAddEventButtonClick,
    previewAddEventModalVisibility,
  } = useContext(GeneralContext);

  return (
    <div className={classes.generate_ticket_container}>
      <h3 className={classes.page_heading}>Add Event</h3>
      <p className={classes.heading_description}>
        Kindly fill in these details to create an event for your users. <br />{" "}
        Kindly note that to add each event cost{" "}
        {`${currencyOfAddingEvent} ${costOfAddingEvent}`}
      </p>

      <div className={classes.all_inputs_container}>
        {/* event name */}
        <div className={classes.input_container}>
          <p className={classes.label}>Event Name</p>
          <input
            onChange={updateAddEventCredentialState}
            name="eventName"
            value={addEventCredentials.eventName}
            className={classes.input}
            placeholder="ENTER EVENT'S NAME"
            type="text"
          />
        </div>

        {/* ticket type */}
        <div className={classes.input_container}>
          <p className={classes.label}>Ticket type</p>
          <input
            onChange={updateAddEventCredentialState}
            name="ticketType"
            value={addEventCredentials.ticketType}
            className={classes.input}
            placeholder="ENTER TICKET TYPE (e.g REGULAR)"
            type="text"
          />
        </div>

        {/* currency */}
        <div className={classes.smaller_input_container}>
          <p className={classes.label}>Currency</p>
          <select
            onChange={updateAddEventCredentialState}
            value={addEventCredentials.currency}
            name="currency"
            className={classes.input}
          >
            <option>---</option>
            {currencyArray.map((item, index) => (
              <option key={index}>{item}</option>
            ))}
          </select>
        </div>

        {/* ticket price */}
        <div className={classes.smaller_input_container}>
          <p className={classes.label}>Ticket price</p>
          <input
            onChange={updateAddEventCredentialState}
            name="ticketPrice"
            value={addEventCredentials.ticketPrice}
            className={classes.input}
            placeholder="ENTER TICKET PRICE"
            type="number"
          />
        </div>

        {/* day */}
        <div className={classes.smallest_input_container}>
          <p className={classes.label}>Event day</p>
          <select
            onChange={updateAddEventCredentialState}
            value={addEventCredentials.eventDay}
            name="eventDay"
            className={classes.input}
          >
            <option>DD</option>
            {dayArray.map((item, index) => (
              <option key={index}>{item}</option>
            ))}
          </select>
        </div>

        {/* month */}
        <div className={classes.smallest_input_container}>
          <p className={classes.label}>Event month</p>
          <select
            onChange={updateAddEventCredentialState}
            value={addEventCredentials.eventMonth}
            name="eventMonth"
            className={classes.input}
          >
            <option>MM</option>
            {monthArray.map((item, index) => (
              <option key={index}>{item}</option>
            ))}
          </select>
        </div>

        {/* year */}
        <div className={classes.smallest_input_container}>
          <p className={classes.label}>Event year</p>
          <select
            onChange={updateAddEventCredentialState}
            value={addEventCredentials.eventYear}
            name="eventYear"
            className={classes.input}
          >
            <option>YY</option>
            {yearArray.map((item, index) => (
              <option key={index}>{item}</option>
            ))}
          </select>
        </div>

        {/* event time */}
        <div className={classes.input_container}>
          <p className={classes.label}>Event time</p>
          <select
            value={addEventCredentials.eventTime}
            onChange={updateAddEventCredentialState}
            name="eventTime"
            className={classes.input}
          >
            <option>--:--</option>
            {timeArray.map((item, index) => (
              <option key={index}>{item}</option>
            ))}
          </select>
        </div>

        {/* Scan starts */}
        <div className={classes.input_container}>
          <p className={classes.label}>Ticket Scan starts</p>
          <select
            onChange={updateAddEventCredentialState}
            value={addEventCredentials.scanStarts}
            name="scanStarts"
            className={classes.input}
          >
            <option>--:--</option>
            {timeArray.map((item, index) => (
              <option key={index}>{item}</option>
            ))}
          </select>
        </div>
      </div>

      {/* button */}
      <div className={classes.generate_button_container}>
        {loading ? (
          <CircularProgress
            className={classes.loading_icon}
            isIndeterminate
            color="purple.300"
          />
        ) : (
          <p
            onClick={onAddEventButtonClick}
            className={classes.generate_button}
          >
            Preview
          </p>
        )}
      </div>
      {previewAddEventModalVisibility && <AddEventsPreviewModal />}
    </div>
  );
}
export default AddEvent;
