import { Route, Routes, useNavigate } from "react-router-dom";
import Header from "./utils/Header/header";
import Register from "./utils/Register/register";
import { useContext, useEffect } from "react";
import GeneralContext from "./context/generalContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LogIn from "./utils/LogIn/login";
import SidePane from "./pages/dashboard/sidePane";
import classes from "./App.module.css";
import PrivateRoute from "./routing/privateRoute";
import GenerateTicket from "./pages/dashboard/generateTicket";
import ScanTicket from "./pages/dashboard/scanTicket";
import AddEvent from "./pages/dashboard/addEvent";

function App() {
  const { registerModal, logInModal, toggleLogInModal } = useContext(
    GeneralContext
  );

  const navigate = useNavigate(); //navigate react hook

  // route to homepage

  // route to dashboard
  const routeToDashboard = () => {
    navigate("/dashboard");
  };

  useEffect(() => {
    localStorage.removeItem("tmt");
    toggleLogInModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <ToastContainer />
      {registerModal && <Register />}{" "}
      {/* Display the register modal if register modal state is true */}
      {logInModal && <LogIn />}{" "}
      {/* Display the register modal if login modal state is true */}
      <Header />
      <Routes>
        {/* Route doesn't exist */}
        <Route path="*" element={<div>Route doesn't exist</div>} />

        {/* Home */}
        <Route path="/" element={<div>Home</div>} />

        {/* About */}
        <Route path="/about" element={<div>About</div>} />

        {/* Contact */}
        <Route path="/contact" element={<div>Contact</div>} />

        {/* dashboard/overview */}
        <Route
          path="/dashboard"
          element={
            <div className={classes.dashboard_container}>
              <PrivateRoute>
                <SidePane />
              </PrivateRoute>
            </div>
          }
        />

        {/* generate ticket */}
        <Route
          path="/dashboard/generate_ticket"
          element={
            <div className={classes.dashboard_container}>
              <PrivateRoute>
                {/*side pane that contains all the vertical menu  */}{" "}
                <SidePane />{" "}
                {/* The area that allows user to input details for generating ticket */}
                <GenerateTicket /> {/*  */}
              </PrivateRoute>
            </div>
          }
        />

        {/* scan ticket */}
        <Route
          path="/dashboard/scan_ticket"
          element={
            <div className={classes.dashboard_container}>
              <PrivateRoute>
                {/*side pane that contains all the vertical menu  */}{" "}
                <SidePane /> {/* The area that allows user to scan ticket */}
                <ScanTicket /> {/*  */}
              </PrivateRoute>
            </div>
          }
        />

        {/* add events */}
        <Route
          path="/dashboard/add_event"
          element={
            <div className={classes.dashboard_container}>
              <PrivateRoute>
                {/*side pane that contains all the vertical menu  */}{" "}
                <SidePane />{" "}
                {/* The area that allows user to input details for adding events */}
                <AddEvent />
              </PrivateRoute>
            </div>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
